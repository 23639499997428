import React from 'react';
import Technology from '../../assets/SayIn/technology.jpg'

export default function ContinuousUpdates(props) {

    return (
        <>
            <div className='fleX max-width jeS-C' >
                <img src={Technology} alt="Example" className='continuous_Us_Img' />
                <div className='fleX continuous_Us_'>
                    <div className='card__title foNT-m-M,'>Continuous Updates and Threat Intelligence</div>
                    <div className='card__content foNT-S-M'>Our virus signature database is updated hourly to protect against the latest threats, giving you confidence that your system is secure from new and evolving malware. This ensures that your organization is always protected with the most up-to-date defense.</div>
                </div>
                <img src={Technology} alt="Example" className='continuous_Us_Img' />
            </div>
        </>
    )
}