import React, { useState, useEffect } from 'react';
import v_Logo from '../../assets/Frame B.svg';
import { RxHamburgerMenu } from "react-icons/rx";
import { CgClose } from "react-icons/cg";
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

export default function Navbar() {

    const navigateP = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [path, setPath] = useState('About Us');
    const [menuName, setMenuName] = useState('');
    const [isVisible, setIsVisible] = useState();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY) {
                setIsVisible(window.scrollY);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const menuItems = [
        {
            id_C: 1,
            label: 'About Us',
            path: "/automation",
            command: () => { handleClick('About Us') }
        },
        {
            id_C: 2,
            label: 'API Documentation',
            path: "/modified",
            command: () => { handleClick('API Documentation') }
        },
        {
            id_C: 3,
            label: 'Contact Us',
            path: "/search",
            command: () => { handleClick('Contact Us') }
        },
    ];

    const handleClick = (value) => {
        setMenuName(value);
        setPath(value);
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const navigate = (data) => {
        setPath(data?.label);
        setIsOpen(!isOpen);
        if (data?.id_C === 1) {
            navigateP('/')
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else if (data?.id_C === 2) {
            window.scrollTo({ top: windowWidth <= 800 ? 400 : 0, behavior: 'smooth' });
            window.open('https://api.vcheckk.com/redoc-static.html', '_blank')
        } else if (data?.id_C === 3) {
            window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
        }
    }

    return (
        <>
            <div className='w-full' style={{ position: 'fixed', maxWidth: windowWidth >= 1400 ? '180%' : '', width: '-webkit-fill-available', background: isVisible >= 100 ? '#ffffff4a' : '', padding: '25px', zIndex: '9' }}>
                <div className='max-width fleX'>
                    <div className='w-full fleX'>
                        <img src={v_Logo} alt="Example" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} style={{ height: windowWidth <= 800 ? '30px' : '', padding: windowWidth <= 800 ? '' : '25px 0', cursor: 'pointer' }} />

                        {!isOpen ? <RxHamburgerMenu style={{ display: windowWidth <= 800 ? 'flex' : 'none' }} className='ha-M cursor' onClick={(event) => setIsOpen(!isOpen)} /> :
                            <CgClose style={{ display: windowWidth <= 800 ? 'flex' : 'none' }} className='ha-M cursor' onClick={(event) => setIsOpen(!isOpen)} />
                        }

                        <div className='w-full fleX' style={{ display: windowWidth <= 800 ? 'none' : 'flex', justifyContent: 'center' }}>
                            <div className='fleX b_Menu' style={{ width: 'fit-content', height: windowWidth <= 1000 ? '75px' : '90px', padding: '0 25px', gap: windowWidth <= 1000 ? '15px' : '50px' }}>
                                {menuItems?.map((item, index) => (
                                    <Button key={index} label={item?.label} className={item?.label === path ? ' meNU_BTN mBTN-h' : 'meNU_BTN'} onClick={(e) => navigate(item)} />
                                ))}
                            </div>
                        </div>

                        {isOpen &&
                            <div className='ham-Berger fleX' style={{ display: windowWidth >= 800 ? 'none' : '', top: windowWidth <= 800 ? '65px' : '70px' }}>
                                {menuItems.map((data, index) => (
                                    <div style={{ cursor: 'pointer', fontSize: '16px' }} key={index} className={data?.label === path ? ' meNU_BTN mBTN-h' : 'meNU_BTN'} onClick={() => navigate(data)}>{data?.label}</div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )

}