import React, { useEffect } from "react";
import { FaRegCheckSquare } from "react-icons/fa";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { IoMdCloseCircle } from "react-icons/io";
import { ZIndexUtils } from "primereact/utils";

export default function Tost(props) {


    useEffect(() => {
        setTimeout(() => {
            props?.clearData()
        }, props?.tost?.left)
    })

    const tistRes = {
        justifyContent: 'flex-end',
        color: '#e7fef7',
        background: props?.tost?.bg,
        maxWidth: '350px',
        padding: '20px',
        borderRadius: '5px',
        position: 'fixed',
        right: '15px',
        top: '100px',
        zIndex: '999'
    };

    const inTistRes = {
        width: '150px',
        height: '60px',
        background: '#ffffff40',
        borderRadius: '8px',
        justifyContent: 'center',
        fontSize: '22px'
    }

    const closed = {
        width: 'auto',
        height: '60px',
        justifyContent: 'center',
        fontSize: '2px',
        position: 'relative',
        bottom: '25px',
        cursor: 'pointer'
    }

    return (
        <>
            {props?.tost &&
                <div className='fleX' style={tistRes}>
                    <i className="fleX" style={inTistRes}>{props?.tost?.tittle === 'Success' ? <FaRegCheckSquare /> : <AiOutlineCloseSquare />}</i>
                    <div className='fleX' style={{ flexDirection: 'column', gap: '8px', width: '450px' }}>
                        <div className='w-full' style={{ color: '#FFFF', fontWeight: '600' }}>{props?.tost?.tittle}</div>
                        <div className='w-full' style={{ color: '#FFFF', fontSize: '12px', fontWeight: '400' }}>{props?.tost?.message}</div>
                    </div>
                    <IoMdCloseCircle style={closed} onClick={() => props?.clearData()} />
                </div>
            }
        </>
    )

}