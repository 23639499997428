import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Hone from './pages/Home/Home';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Hone />} />
        </Routes>
      </Router >
    </>
  );
}

export default App;
