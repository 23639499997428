import React, { useState, useEffect } from 'react';
import Ellipse from '../../assets/Features_Ass/Ellipse.svg'
import arrow from '../../assets/arrow.svg';

export default function Features(props) {

    const divStyle = {
        flexDirection: 'column',
        alignItems: 'flex-start',
    };

    const featuresDivStyle = {
        color: '#0197B0',
        fontSize: '32px',
        fontWeight: '500'
    };

    const item = props?.keyItem;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div className='card fleX max-width  moB-wrap' style={{ flexDirection: item?.key === 1 || item?.key === 3 || item?.key === 5 ? 'unset' : 'row-reverse' }}>
                <div className='card_R fleX' style={divStyle} >
                    {item?.key === 1 ?
                        <div className='card__title' style={featuresDivStyle}>Features<img src={arrow} style={{ padding: '0 5px', height: '15px' }} alt="Example" /> </div>
                        : null
                    }
                    <div className='card__title foNT-m-M'> {item?.title} </div>
                    <div className='card__content foNT-S-M'>{item?.content}</div>
                </div>
                <div className={item?.key === 1 ? 'card_L imG fleX' : 'card_L fleX'} style={{ alignItems: 'center', justifyContent: 'center' }}>
                    {item?.key === 1 ?
                        <>
                            <div className='features_img_S fleX mob-SEC'>
                                <div className='features_img_S1'>{item?.subTitle}</div>
                                <div className='features_img_S2'>{item?.subTitle_}</div>
                                <img src={item?.image} alt="Example" />
                            </div>
                            <img src={Ellipse} alt="Example" className='iMG' />
                        </>
                        :
                        <img src={item?.image} alt="Example" className='m-IMG' style={{ width: item?.key === 4 && windowWidth <= 575 ? 'auto' : '', height: item?.key === 1 && windowWidth <= 575 ? '200px' : '' }} />
                    }
                </div>
            </div >
        </>
    )
}