import React from 'react';
import Logo from '../../assets/Media/AlthI-Logo.svg';
import fb from '../../assets/Media/fb.svg';
import tw from '../../assets/Media/tw.svg';
import ins from '../../assets/Media/ins.svg';
import lin from '../../assets/Media/in.svg';

export default function Footer() {

    const nameList = [
        { nameNote: 'Cookies Policy' },
        { nameNote: 'Terms & Conditions' },
        { nameNote: 'Privacy Policy' },
    ]

    const mediaList = [
        { img: fb, path: 'https://www.facebook.com/AlthiSolutions' },
        { img: tw, path: 'https://x.com/i/flow/login?redirect_after_login=%2FAlthiSolutions%2F' },
        { img: ins, path: 'https://www.instagram.com/althisolutions' },
        { img: lin, path: 'https://www.linkedin.com/authwall?trk=bf&trkInfo=AQE3S_D1QrDUGQAAAZEL-FIIk1NkUwpeITA2NM_tFBrSpcOGNjFxF7ffnwwUesn9_8LZn7oIOMy8Orizq43KHyW9FFn5yn7jjAcXoj2pZY_eohIPTzYy-QMo5tSS92Vm6uCMz4M=&original_referer=&sessionRedirect=https%3A%2F%2Fin.linkedin.com%2Fcompany%2Falthisolutions' },
    ]

    return (
        <>
            <div className='fleX max-width fOO-t'>
                <div className='fleX'>
                    <span className='vs-font'>Powered By</span>
                    <a href={'https://www.althisolutions.com/'} target='_blank' >
                        <img src={Logo} alt="logo" className='h-25' />
                    </a>
                </div>

                <div className='fleX'>
                    {mediaList.map((iten, index) => (
                        <a key={index} href={iten?.path} target='_blank'>
                            <img src={iten?.img} alt="logo" />
                        </a>
                    ))}
                </div>

                <div className='vs-font vs-fontB'>Building On Trust.</div>

                <div className='fleX m-Warp'>
                    {nameList.map((iten, index) => (
                        <span key={index} className='vs-font cursor'>{iten?.nameNote}</span>
                    ))}
                </div>
            </div>
        </>
    )

}