import React, { useState, useEffect } from 'react';
import profile_1 from '../../assets/SayIn/Ellipse 122.png'
import profile_2 from '../../assets/SayIn/Ellipse 123.png'
import arrow from '../../assets/arrow.svg';
import nex from '../../assets/n.png';

export default function PeopleSays() {

    const peopleSayIn = [
        {
            image: profile_1,
            name: 'Mark R.',
            note: '"As a small business owner, the Vcheck free tool has been invaluable. The API integration was straightforward, and knowing my files are safe gives me peace of mind."'
        },
        {
            image: profile_2,
            name: 'David K.',
            note: '"Integrating Vcheck into my website was a breeze. The detailed instructions and API keys provided made the process seamless. Highly recommended!"'
        }
    ]

    return (
        <>
            <div className='fleX max-width card-Pad hed_gaP' style={{ flexDirection: 'column' }}>
                <div className='card__header-title'>Hear What The People Says<img src={arrow} style={{ padding: '0 5px', height: '15px' }} alt="Example" /></div>

                <div className='peopleSayIN fleX'>
                    <div className='fleX peopleSayIN-s f-Direction-T'>
                        {peopleSayIn.map((item, index) => (
                            <div key={index} className='say-Note fleX' style={{ justifyContent: 'center', flexDirection: 'column', padding: '0 25px' }}>
                                <div className='fleX' style={{ width: '100%', gap: '25px', justifyContent: 'unset' }}>
                                    <img className='' src={item?.image} alt="Example" />
                                    <div className='say-Tittle foNT-m-M'>{item?.name}</div>
                                </div>
                                <div className='m-hide comment foNT-S-M'>{item?.note}</div>
                                <div className='fleX nex-Sec m-hide'>
                                    <img src={nex} className='nex' alt="Example" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )

}