import React, { useState, useEffect, useRef } from 'react';
import Loader from '../../components/Loader/Loader';
import Features from '../../components/Features/Features';
import features_L1_IMG from '../../assets/Features_Ass/f1.png';
import features_L2_IMG from '../../assets/Features_Ass/f2.webp';
import features_L3_IMG from '../../assets/Features_Ass/f3.png';
import features_L4_IMG from '../../assets/Features_Ass/f4.png';
import features_L5_IMG from '../../assets/Features_Ass/f5.png';
import ContinuousUpdates from '../../components/ContinuousUpdates/ContinuousUpdates';
import HowItWorks from '../../components/HowItWorks/HowItWorks'
import PeopleSays from '../../components/PeopleSays/PeopleSays';
import Footer from '../../components/Footer/Footer';
import Faq from '../../components/Faq/Faq';
import Banner from '../../components/Banner/Banner';

export default function Hone() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const features_L1 = {
        key: 1,
        title: 'Unlimited Malware Defense',
        subTitle: 'Malware Scanning',
        subTitle_: 'Robust Infrastructure',
        content: 'We offer limitless malware and virus scanning across a vast range of file formats, with continuous updates to our virus database, ensuring real-time protection from emerging threats.',
        image: features_L1_IMG,
    }

    const features_L2 = {
        key: 2,
        title: 'End-to-End Security',
        content: 'Every upload through our API is fully encrypted using modern TLS protocols, safeguarding data integrity and confidentiality during transit. Our encryption standards meet current security benchmarks to prevent tampering or unauthorized access.',
        image: features_L2_IMG,
    }

    const features_L3 = {
        key: 3,
        title: 'API-Exclusive Access',
        content: 'Access and manage files exclusively via our API, which employs strong authentication and authorization protocols. This ensures only verified users can interact with the system, enhancing security by preventing unauthorized use.',
        image: features_L3_IMG,
    }

    const features_L4 = {
        key: 4,
        title: 'Broad Language and Framework Support',
        content: 'Our API supports seamless integration with popular programming languages such as JavaScript, Python, PHP, and Java. This flexibility allows you to integrate our malware-scanning service into virtually any environment or application with ease.',
        image: features_L4_IMG,
    }

    const features_L5 = {
        key: 5,
        title: 'Archive and Large File Support',
        content: 'Scan a wide range of compressed and archive formats, including ZIP, RAR, TAR, and others. Recursive scanning ensures that even nested archives are thoroughly checked for malware. Large files are supported with sub-second response times for optimal performance.',
        image: features_L5_IMG,
    }

    return (
        <>
            {loading &&
                <Loader />
            }

            <Banner />

            <Features keyItem={features_L1} />
            <div className='features_b' />

            <Features keyItem={features_L2} />
            <div className='features_b' />

            <Features keyItem={features_L3} />
            <div className='features_b' />

            <Features keyItem={features_L4} />
            <div className='features_b' />

            <Features keyItem={features_L5} />
            <div className='features_b' />

            <ContinuousUpdates />
            <div className='features_b' />

            <HowItWorks />

            <div style={{ background: '#3D3A3A' }}>
                <Faq />
            </div>

            <PeopleSays />
            <div className='features_b' />

            <Footer />
        </>
    )
}