import React, { useState, useEffect } from 'react';
import img_C1 from '../../assets/HowItWorks_Ass/Group 45.png'
import img_C2 from '../../assets/HowItWorks_Ass/Group 47.png';
import img_C3 from '../../assets/HowItWorks_Ass/Group 50.png';
import img_C4 from '../../assets/HowItWorks_Ass/Group 48.png';
import img_T1 from '../../assets/HowItWorks_Ass/Group 51.png'
import img_T2 from '../../assets/HowItWorks_Ass/Group 42.png';
import img_T3 from '../../assets/HowItWorks_Ass/Group 43.png';
import img_T4 from '../../assets/HowItWorks_Ass/Group 44.png';
import dot_L from '../../assets/HowItWorks_Ass/Line 107.png';
import arrow from '../../assets/arrow.svg';
import Group from '../../assets/Group.png'

export default function HowItWorks() {

    const HowItWorks_List = [
        {
            title: 'Get Your Free API Key',
            content: 'Complete a quick registration process to obtain your unique API keys. These keys, delivered securely via email, grant you access to upload and download functionalities. They ensure every file transfer is authenticated, safeguarding your system from unauthorized access while maintaining the integrity of the scanning process.',
            image_C: img_C1,
            image_T: img_T1,
        },
        {
            title: 'Seamless Integration',
            content: 'Easily integrate our API into your existing web forms or applications, providing real-time protection without any impact on performance. Our detailed documentation ensures your website or application will benefit from enhanced security with no complicated setup.',
            image_C: img_C2,
            image_T: img_T2,
        },
        {
            title: 'Secure File Handling',
            content: 'Files uploaded via our API undergo advanced malware scanning, harnessing the latest threat intelligence. Any file flagged as malicious gets rejected, while clean files are encrypted and made available for safe download. This dual layer scanning and encryption approach ensures that your files are protected at every step, from upload to transfer.',
            image_C: img_C3,
            image_T: img_T3,
        },
        {
            title: 'Automatic File Deletion',
            content: 'VCheck ensures maximum privacy and security by deleting scanned files from its server within 24 hours. This strict policy guarantees the best practices in data minimization and privacy protection, ensuring compliance.',
            image_C: img_C4,
            image_T: img_T4,
        },
    ]

    const [count, setCount] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCount(prevCount => (prevCount < HowItWorks_List.length - 1 ? prevCount + 1 : 0));
        }, 4000);
        return () => clearInterval(interval);
    }, [count]);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div className='fleX max-width card-Pad hed_gaP' style={{ flexDirection: 'column' }}>
                <div className='card__header-title'>How It Works<img src={arrow} style={{ padding: '0 5px', height: '15px' }} alt="Example" /></div>

                <div className='howItWorks_List fleX'>
                    {HowItWorks_List.map((item, index) => (
                        <div key={index} className='fleX ga-HTW' style={{ alignItems: 'flex-start' }}>
                            <div style={{ textAlign: 'center' }}>
                                <div className={count === index ? 'isActive fleX cursor' : 'hIW_Lt_IMG fleX cursor'}>
                                    <img className='howItWorks_List_IMG IMG_C' src={item?.image_C} alt="Example" />
                                    <img className='howItWorks_List_IMG IMG_T' src={item?.image_T} alt="Example" />
                                </div>
                                <img className='bar_p' style={{ display: item?.title === 'Automatic File Deletion' ? 'none' : '' }} src={dot_L} alt="Example" />
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                <div className='howItWorks_T  foNT-m-M'>{item?.title}</div>
                                <div className='howItWorks_C foNT-S-M'>{item?.content}</div>
                            </div>
                        </div>
                    ))}
                </div>
                <img src={Group} alt="Example" className='d-OB' style={{ display: windowWidth >= 2110 ? 'none' : '' }} />
            </div>
        </>
    )
}