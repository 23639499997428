import React, { useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import img from '../../assets/SayIn/faq_.svg';
import { FaAngleUp } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import arrow from '../../assets/arrow.svg';

export default function Faq() {

    const items = [
        {
            name: 'Is VCheck free?',
            note: "Yes, Vcheck is completely free to use. Our goal is to provide a reliable and secure malwarescanning service at no cost",
            id: 0,
        },
        {
            name: 'How does the automatic deletion work?',
            note: "Yes, Vcheck is completely free to use. Our goal is to provide a reliable and secure malwarescanning service at no cost",
            id: 1,
        },
        {
            name: 'Can I trust Vcheck with my sensitive files?',
            note: "Yes, Vcheck is completely free to use. Our goal is to provide a reliable and secure malwarescanning service at no cost",
            id: 2,
        },
        {
            name: 'How can I get support if I encounter issues?',
            note: "Yes, Vcheck is completely free to use. Our goal is to provide a reliable and secure malwarescanning service at no cost",
            id: 3,
        },
        {
            name: 'Can I retrieve my files after 24 hours?',
            note: "Yes, Vcheck is completely free to use. Our goal is to provide a reliable and secure malwarescanning service at no cost",
            id: 4,
        },
    ];

    const [activeIndex, setActiveIndex] = useState(0);

    const handleTabChange = index => {
        setActiveIndex(prevIndex => (prevIndex === index ? null : index));
    };

    const isTabOpen = index => activeIndex === index;

    const renderAccordionTabHeader = (headerText, isOpen, notes) => (
        <>
            <div className='fleX fa-Q' style={{ width: activeIndex === 0 || !isOpen ? '100%' : '100%', fontWeight: '600', justifyContent: 'space-between' }}>

                {isOpen ? <div className='acc_D_I_Sec fleX'><FaAngleUp /></div> : <div className='acc_D_I_Sec fleX exPnd'><FaAngleDown /></div>}

                <div className={` ${isOpen ? 'open_Acc acc_Content' : 'acc_Content'}`} style={{ color: isOpen ? '#FFFF' : '#C4C4C4' }} >
                    {headerText}
                    {isOpen &&
                        <div className='fa-Q fa-Q-C foNT-S-M' style={{ fontWeight: '500' }}>{notes}</div>
                    }
                </div>
            </div>
        </>
    );

    return (
        <>
            <div className='fleX max-width card-Pad' style={{ flexDirection: 'column', gap: '20px' }}>
                <div className='card__header-title' style={{ color: '#FFFFFF' }}>FAQ’s<img src={arrow} style={{ padding: '0 5px', height: '15px' }} alt="Example" /></div>
                <i className="pi pi-times" style={{ fontSize: '50px', color: 'orange' }} />

                <div className='fleX' style={{ width: '100%', alignItems: 'end' }}>
                    <Accordion className='say-Note fleX p-Accordion' style={{ width: activeIndex === 0 || !activeIndex ? '130%' : '100%', height: 'unset', background: 'none', overflow: 'hidden', border: 'none' }} activeIndex={activeIndex} onTabChange={e => handleTabChange(e.index)} >
                        {items.map((item, index) => (
                            <AccordionTab key={index} header={renderAccordionTabHeader(item?.name, isTabOpen(item?.id), item?.note)} />
                        ))}
                    </Accordion>

                    <div className='faq_F'>
                        <img src={img} alt="logo"/>
                    </div>
                </div>
            </div>
        </>
    )

}